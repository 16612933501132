<template>
  <v-timeline
    align-top
    dense
    class="pr-3"
  >
    <v-timeline-item
      v-for="(item, i) in histories"
      :key="i"
      :color="statusBackgroundColor(item.status)"
      :icon="statusIcon(item.status)"
      fill-dot
    >
      <v-card
        :color="statusBackgroundColor(item.status)"
      >
        <v-card-title class="text-body-2 text-capitalize font-weight-6" :class="statusTextColor(item.status)">
          {{ item.status }}
          <small></small>
        </v-card-title>
        <v-card-text class="last-update white grey--text pt-3">
          <small class="font-weight-6">Updated</small> @ <small class="font-weight-6">{{ formatDateTime(item.date_created) }}</small>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import kebabCase from 'lodash/kebabCase';

export default {
  name: 'OrderTrackingStepper',
  props: ['histories'],
  data() {
    return {
      timeZone: process.env.VUE_APP_TIMEZONE,
    }
  },
  methods: {
    statusBackgroundColor(status) {
      return kebabCase(status);
    },
    statusTextColor(status) {
      return ['declined', 'cancelled', 'pending'].includes(kebabCase(status)) ? '' : 'white--text';
    },
    statusIcon(status) {
      switch (kebabCase(status)) {
        case 'pending':
          return 'mdi-order-bool-ascending-variant';
        case 'accepted':
          return 'mdi-hand-okay';
        case 'assigning':
          return 'mdi-calendar-search';
        case 'reassign-rider':
          return 'mdi-calendar-sync';
        case 'change-car':
          return 'mdi-car-select';
        case 'rider-accepted':
          return 'mdi-calendar-check';
        case 'rider-pick-up':
          return 'mdi-hand-extended';
        case 'successful':
          return 'mdi-check-all';
        case 'cancelled':
        case 'declined':
          return 'mdi-close-box';
        default:
          return '';
      }
    },
    formatDateTime(dt) {
      return dayjs(dt).utc(this.timeZone).format('YYYY-MM-DD HH:mm');
    },
  },
  created() {
    dayjs.extend(utc);
  },
};
</script>

<style scoped>
.last-update {
  font-size: 11px;
}
</style>
