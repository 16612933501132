<template>
  <v-dialog v-model="show" transition="dialog-bottom-transition">
    <v-card>
      <v-main v-if="order" class="bill p-0" id="pdf" ref="bill">
        <v-container class="font-weight-600">
          <v-row class="mb-4">
            <v-col cols="12" class="text-center">
              <img
                v-if="order.status !== 'declined'"
                src="@/assets/images/successavatar.png"
                class="w-25"
              />
              <img
                v-else
                src="@/assets/images/declinedorder.png"
                class="w-25"
              />

              <div v-if="order.status !== 'declined'" class="text-body-1 mt-4">
                <div>Hurray! Your order is <small :class="'text-body-1 font-weight-bold ml-1 ' + statusTextColor(order.status)">{{ order.status | capitalize }}</small>.</div>
                <div>Order ID : <small class="text-body-1 font-weight-bold">{{ orderid }}</small></div>
                <div class="text-body-1">
                  Contact No: <small class="text-body-1 font-weight-bold light-blue--text text--darken-1">{{ order.restaurant_phone }}</small>
                </div>
              </div>
              <div v-else class="text-body-2 mt-4">
                <div>
                  Sorry! Your order is declined. You will get back your refund
                  in 7 working days
                </div>
                <div class="text-body-2 mt-3">
                  Contact No: <small class="text-body-1 font-weight-bold light-blue--text text--darken-1">{{ order.restaurant_phone }}</small>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <OrderTrackingStepper v-if="order" :histories="order.histories">
              </OrderTrackingStepper>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="output"><img :src="output" /></div>
      </v-main>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderTrackingStepper from '@/components/OrdersHistory/OrderTrackingStepper';
import kebabCase from 'lodash/kebabCase';

export default {
  props: [
    'value',
    'orderid',
    'invid',
    'isHistoryDialog',
    'ordertypeid',
    'merchantid',
  ],
  components: {
    OrderTrackingStepper,
  },
  data: function () {
    return {
      isloading: false,
      orderData: null,
      output: null,
    };
  },
  computed: {
    ...mapState('order', {
      order: (state) => state.order,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions('order', ['getOrder']),
    ...mapMutations("order", ["SET_ORDER"]),
    downloadBill(bill) {
      var link = document.createElement('a');
      link.href = bill;
      link.download = 'bill';
      link.click();
    },
    async fetchOrderData(orderid) {
      this.isloading = true;
      await this.getOrder({
        id: orderid,
        query: {
          history: 1,
        },
      });
      this.isloading = false;
    },
    statusTextColor(status) {
      return `${kebabCase(status)}--text`;
    },
  },
  beforeUnmount() {
    this.SET_ORDER(null);
  },
  mounted() {
    if (this.isHistoryDialog) {
      this.fetchOrderData(this.orderid);
    }
  },
  watch: {
    orderid: function (newval) {
      if (newval) {
        this.fetchOrderData(newval);
      }
    },
  },
};
</script>

<style scoped>
.billlist li::marker {
  content: '- ';
}
.dialog {
  width: 90%;
  height: 100vw;
}

@media screen and (max-width: 375px) {
  .acceptedmodalsactionbtns .v-btn__content {
    font-size: 10px !important;
  }
  .bill h5 {
    font-size: 1rem;
  }
  .bill h4 {
    font-size: 1.2rem;
  }
  .bill h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 360px) {
  .bill h4 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 320px) {
  .bill h4 {
    font-size: 0.7rem;
  }
}
</style>
